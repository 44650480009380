import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';

const initialState={
    favorites:[],
}

const favoriteReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_FAVORITES':
           
            if (!state.favorites.some(product => product.id === action.payload.sef)) {
                return { ...state, favorites: [...state.favorites, action.payload] };
            }
            return state;

        case 'REMOVE_FROM_FAVORITES':
            
            return { ...state, favorites: state.favorites.filter(product => product.sef !== action.payload.sef) };
        case 'LOAD_FAVORITES':
            return { ...state, favorites: action.payload };
            case 'UPDATE_FAVORITE':
            return {
                ...state,
                favorites: state.favorites.map(item => 
                    item.sef === action.payload.sef ? { ...item, fiyat: action.payload.fiyat } : item
                ),
            };
    
        default:
            return state;
    }
};
export const FavoriteContext=createContext();

export const FavoriteProvider = ({ children }) => {
    const [state, dispatch] = useReducer(favoriteReducer, initialState);

    useEffect(() => {
        const fetchFavoriteProducts = async () => {
            const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];

            const updatedFavorites = await Promise.all(
                storedFavorites.map(async (product) => {
                    try {
                        const response = await axios.get(`https://api.bikuyum.com/api/product?sef=${product.sef}`);
                        return { ...product, fiyat: response.data.fiyat };
                    } catch (error) {
                        console.error("Fiyat bilgisi alınırken hata oluştu:", error);
                        return product;
                    }
                })
            );
            dispatch({ type: 'LOAD_FAVORITES', payload: updatedFavorites });
            localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
        };

        fetchFavoriteProducts();
    }, []);

    const checkStock = async () => {
        const updatedFavorites = [];
        for (const product of state.favorites) {
            try {
                const response = await axios.get(`https://api.bikuyum.com/api/product?sef=${product.sef}`);
                if (response.data.stok > 0) {
                    updatedFavorites.push(product);
                } else {
                    dispatch({ type: 'REMOVE_FROM_FAVORITES', payload: product });
                }
            } catch (error) {
                console.error("Stok durumu kontrol edilirken hata oluştu:", error);
            }
        }
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };
    useEffect(() => {
        if (state.favorites.length > 0) {
            checkStock();
        }
    }, [state.favorites]);

    const addToFavorite = (product) => {
        dispatch({ type: 'ADD_TO_FAVORITES', payload: product });
        const currentFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        if (!currentFavorites.some(favProduct => favProduct.sef === product.sef)) {
            currentFavorites.push(product);
            localStorage.setItem('favorites', JSON.stringify(currentFavorites));
        }
    };
    
    const removeFavorite = (product) => {
        dispatch({ type: 'REMOVE_FROM_FAVORITES', payload: product });
        const currentFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        const updatedFavorites = currentFavorites.filter(favProduct => favProduct.sef !== product.sef);
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };

    const loadFavorites = () => {
        const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        dispatch({ type: 'LOAD_FAVORITES', payload: storedFavorites });
    };

    useEffect(() => {
        loadFavorites();
       
    }, []);

    return (
        <FavoriteContext.Provider value={{ ...state, addToFavorite, removeFavorite }}>
            {children}
        </FavoriteContext.Provider>
    );
};

