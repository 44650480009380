
import React, { createContext, useReducer, useEffect } from 'react';



const initialState = {
    products: [],
    loading: true,
    error: null,
    productDetails: null,
    filteredProducts: [],  
    selectedCategory: null,
    store:null,
    currentPage: 1,
    loadingProduct: false, 
    maxPrice:"",
    minPrice:"",
    sortOrder:'asc',
    campaignProduct:[],
};


const productReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCTS_SUCCESS':
            
            const existingIds = new Set(state.products.map(p => p.id));
            const newProducts = action.payload.filter(p => !existingIds.has(p.id));
            return {
                ...state,
                products: [...state.products, ...newProducts],
                loading: false
            };
        case 'FETCH_PRODUCTS_ERROR':
            return { ...state, loading: false, error: action.payload };
        case 'SET_SELECTED_PRODUCT':
            return {...state, productDetails: action.payload, loading: false, loadingProduct: false};
        case 'FETCH_CATEGORY_PRODUCTS_SUCCESS':
            return { ...state, filteredProducts: [...state.filteredProducts, ...action.payload],
                selectedCategory: action.kategori, 
                loading: false };
        case 'FETCH_STORE_SUCCCESS':
            return{...state, store:action.payload, loading:false};
       
        case 'INCREMENT_PAGE':
            return { ...state, currentPage: state.currentPage + 1 };
        case 'FILTER_BY_PRICE':
            const filteredByPrice = state.filteredProducts.length > 0 ? state.filteredProducts : state.products;
            const filtered = filteredByPrice.filter(product => {
                const price = parseFloat(product.fiyat);
                return (
                (state.minPrice === '' || price >= parseFloat(state.minPrice)) &&
                (state.maxPrice === '' || price <= parseFloat(state.maxPrice))
                );
            });
            return {
                ...state,
                filteredProducts: filtered,
            };
            case 'SEARCH_PRODUCTS_SUCCESS':
                return { ...state, products: action.payload, loading: false }; 
                case 'RESET_PRODUCTS':
            return { ...state, products: [], loading: true,  currentPage: 1  };   
        case 'SET_MIN_PRICE':
            return {
            ...state,
            minPrice: action.payload,
            };
        case 'SET_MAX_PRICE':
            return {
            ...state,
            maxPrice: action.payload,
            };
        case 'SORT_PRODUCTS':
            const sortedProducts = [...state.filteredProducts].sort((a, b) => {
            return state.sortOrder === 'asc' ? a.fiyat - b.fiyat : b.fiyat - a.fiyat;
            });
            return { ...state, filteredProducts: sortedProducts };
        case 'SET_SORT_ORDER':
            return { ...state, sortOrder: action.payload };
        case 'SET_LOADING':
                return { ...state, loading: action.payload };
        case 'FETCH_CAMPAIGN_PRODUCT_SUCCESS':
            return{...state, campaignProduct:action.payload, loading:false}
      
        default:
            return state;
    }
};

export const ProductContext = createContext();

export const ProductProvider = ({ children}) => {
    const [state, dispatch] = useReducer(productReducer, initialState);

    const handleMinPriceChange = (e) => {
    
        dispatch({ type: 'SET_MIN_PRICE', payload: e.target.value });
      };
    
      const handleMaxPriceChange = (e) => {
        dispatch({ type: 'SET_MAX_PRICE', payload: e.target.value });
      };
    
      const filterByPrice = () => {
        dispatch({ type: 'FILTER_BY_PRICE' });
      };
        const fetchProducts = async (page) => {
            
            try {
                const response = await fetch(`https://api.bikuyum.com/api/products?page=${page}`);
                const data = await response.json();
                const correctedData = data.map(products => {
                    if (typeof products.image === 'string') {
                        
                        try {
                            const imageUrls = JSON.parse(products.image.replace(/\\/g, ''));
                            return {
                                ...products,
                                image: imageUrls 
                            };
                        } catch (error) {
                            console.error('Image JSON parse edilemedi:', error);
                            return products; 
                        }
                    }
                    return products; 
                });
                dispatch({ type: 'FETCH_PRODUCTS_SUCCESS',  payload: correctedData });
                
            } catch (error) {
                dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
        };
       
        const searchProducts = async (query) => {
           
            dispatch({ type: 'SET_LOADING', payload: true }); 
            try {
                const response = await fetch(`https://api.bikuyum.com/api/products?search=${query}`);
                const data = await response.json();
                const correctedData = data.map(product => {
                    if (typeof product.image === 'string') {
                        try {
                            const imageUrls = JSON.parse(product.image.replace(/\\/g, ''));
                            return { ...product, image: imageUrls };
                        } catch (error) {
                            console.error('Image JSON parse edilemedi:', error);
                            return product;
                        }
                    }
                    return product;
                });
                dispatch({ type: 'SEARCH_PRODUCTS_SUCCESS', payload: correctedData });
            } catch (error) {
                dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
        };   
    
    const fetchProductById = async (sef) => {
        dispatch({ type: 'SET_LOADING_PRODUCT', payload: true });
        try {
            const response = await fetch(`https://api.bikuyum.com/api/product?sef=${sef}`);
            const productDetails = await response.json(); 
        if (typeof productDetails.image === 'string') {
            try {
                const imageUrls = JSON.parse(productDetails.image.replace(/\\/g, ''));
                return {...productDetails,
                    image :imageUrls };
            } catch (error) {
                console.error('Image JSON parse edilemedi:', error);
                return productDetails;
            }
            
        }
        
            dispatch({ type: 'SET_SELECTED_PRODUCT', payload: productDetails });
        } catch (error) {
            dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
        }
   
        const fetchCategoryProducts = async (kategori, page) => {
            try {
                
              const response = await fetch(`https://api.bikuyum.com/api/products?category=${kategori}&page=${page}`);
              const data = await response.json();
              const correctedData = data.map(filteredProducts => {
                if (typeof filteredProducts.image === 'string') {
                  try {
                    const imageUrls = JSON.parse(filteredProducts.image.replace(/\\/g, ''));
                    return { ...filteredProducts, image: imageUrls };
                  } catch (error) {
                    console.error('Image JSON parse edilemedi:', error);
                    return filteredProducts;
                  }
                }
                return filteredProducts;
              });
              dispatch({ type: 'FETCH_CATEGORY_PRODUCTS_SUCCESS', payload:correctedData, kategori: kategori });
              dispatch({ type: 'INCREMENT_PAGE' }); 
            } catch (error) {
              dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
            }
           }
           
            const fetchStoreProduct = async () => {
                try {
                    const response = await fetch('https://api.bikuyum.com/api/stores');
                    const data = await response.json();
                    const correctedData = data.map(store => {
                        if (typeof store.image === 'string') {
                            
                            if (store.image.startsWith("http")) {
                                return { ...store, image: [store.image] }; 
                            }
                           
                            try {
                                const imageUrls = JSON.parse(store.image.replace(/\\/g, ''));
                                return { ...store, image: imageUrls };
                            } catch (error) {
                                console.error('Image JSON parse edilemedi:', error);
                                return store; 
                            }
                        }
                        return store;
                    });
                    dispatch({ type: 'FETCH_STORE_PRODUCT_SUCCESS', payload: correctedData });
                    
                } catch (error) {
                    dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
                }
            };
            const fetchCampaignProduct = async () => {
                try {
                    const response = await fetch(`https://api.bikuyum.com/api/products?store=kampanyali-urunler`);
                    const data = await response.json();
                    const correctedData = data.map(campaignProduct => {
                        if (typeof campaignProduct.image === 'string' ) {
                            try {
                              const imageUrls = JSON.parse(campaignProduct.image.replace(/\\/g, ''));
                              return { ...campaignProduct, image: imageUrls };
                            } catch (error) {
                              console.error('Image JSON parse edilemedi:', error);
                              return campaignProduct;
                            }
                          }
                          return campaignProduct;
                        });
                    dispatch({ type: 'FETCH_CAMPAIGN_PRODUCT_SUCCESS', payload: correctedData });
                    
                } catch (error) {
                    dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
                }
            };
           
            const filterProductsByCategory = (categoryId, storeId) => {
                const filtered = state.products.filter(product => 
                    product.categoryId === categoryId && product.storeId === storeId
                );
                dispatch({ type: 'FILTER_PRODUCTS_BY_CATEGORY', payload: { filtered, categoryId, storeId } });
            };
              
            const handleSortChange = (e) => {
                const newSortOrder = e.target.value;
                dispatch({ type: 'SET_SORT_ORDER', payload: newSortOrder });
                dispatch({ type: 'SORT_PRODUCTS' });
            }; 
    return (
        <ProductContext.Provider value={{ state,  dispatch, handleSortChange, handleMaxPriceChange, fetchCampaignProduct, handleMinPriceChange, filterByPrice, searchProducts, filterProductsByCategory, fetchProductById, fetchProducts, fetchStoreProduct, fetchCategoryProducts }}>
            {children}
        </ProductContext.Provider>
    );
};