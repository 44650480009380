import React, { createContext, useReducer, useEffect, useState, useContext } from 'react';

const initialState = {
    card: JSON.parse(localStorage.getItem('card')) || [],
    total: 0,
  };
  
 
  const cardReducer = (state, action) => {
    let updatedCard;
    switch (action.type) {
      case 'ADD_TO_CARD':
        updatedCard = [...state.card, action.payload];
        localStorage.setItem('card', JSON.stringify(updatedCard));
        return { ...state, card: updatedCard };
      case 'REMOVE_FROM_CARD':
        updatedCard = state.card.filter(product => product.id !== action.payload.id);
        localStorage.setItem('card', JSON.stringify(updatedCard));
        return { ...state, card: updatedCard };
      case 'INCREASE_QUANTITY':
        updatedCard = state.card.map(item =>
          item.id === action.payload.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        localStorage.setItem('card', JSON.stringify(updatedCard));
        return { ...state, card: updatedCard };
      case 'DECREASE_QUANTITY':
        updatedCard = state.card.map(item =>
          item.id === action.payload.id && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        localStorage.setItem('card', JSON.stringify(updatedCard));
        return { ...state, card: updatedCard };
        case 'CALCULATE_TOTAL':
          const totalAmount = state.card.reduce((acc, product) => {
              return acc + (product.fiyat * (product.quantity || 1));
          }, 0);
          return { ...state, total: totalAmount.toFixed(2) }; 
          case 'UPDATE_PRODUCT_PRICE':
            updatedCard = state.card.map(product =>
                product.id === action.payload.id
                    ? { ...product, fiyat: action.payload.fiyat }
                    : product
            );
            localStorage.setItem('card', JSON.stringify(updatedCard));
            return { ...state, card: updatedCard };
            case "CHECK_STOCK":
              updatedCard = state.card.filter(item => item.stok > 0);
              localStorage.setItem('card', JSON.stringify(updatedCard));
              return { ...state, card: updatedCard };
      default:
        return state;
    }
  };
  
export const CardContext = createContext();

export const CardProvider=({children})=>{
      const [state, dispatch] = useReducer(cardReducer, initialState);

      const fetchAndUpdateCart = async () => {
        const card = JSON.parse(localStorage.getItem('card')) || [];
        const updatedItems = await Promise.all(card.map(async (item) => {
            const response = await fetch(`https://api.bikuyum.com/api/product?sef=${item.sef}`);
            const productData = await response.json();
            return { ...item, fiyat: productData.fiyat }; 
        }));

        localStorage.setItem('card', JSON.stringify(updatedItems));
        updatedItems.forEach(item => {
            dispatch({ type: 'UPDATE_PRODUCT_PRICE', payload: { id: item.id, fiyat: item.fiyat } });
        });
    };
  useEffect(() => {
        fetchAndUpdateCart();
    }, []);
    const checkStock = async () => {
      
      const updatedItems = await Promise.all(
          state.card.map(async (item) => {
              const response = await fetch(`https://api.bikuyum.com/api/product?sef=${item.sef}`);
              const productData = await response.json();
              return { ...item, stok: productData.stok };
          })
      );
  
      
      updatedItems.forEach(item => {
          if (item.stok === 0) {
              dispatch({ type: 'REMOVE_FROM_CARD', payload: item });
          }
      });
      
      
      const filteredItems = updatedItems.filter(item => item.stok > 0);
      localStorage.setItem('card', JSON.stringify(filteredItems));
  };
  
  useEffect(() => {
      checkStock();
  }, [state.card]);
  const addToCard = async (product) => {
    const response = await fetch(`https://api.bikuyum.com/api/product?sef=${product.sef}`);
    const productData = await response.json();
    
    const existingProduct = state.card.find((item) => item.sef === product.sef);

    if (productData.stok > 0) {
        if (existingProduct) {
           
            if (existingProduct.quantity >= productData.stok) {
                
            } else {
                
                dispatch({ type: 'INCREASE_QUANTITY', payload: product });
            }
        } else {
           
            const newProduct = { ...product, quantity: 1, stok: productData.stok };
            dispatch({ type: 'ADD_TO_CARD', payload: newProduct });
        }
    } else {
       
      
    }
};
      const removeFromCard=(products)=>{
        dispatch({type:'REMOVE_FROM_CARD', payload:products});
      };
      const increaseQuantity = (products) => {
        dispatch({ type: 'INCREASE_QUANTITY', payload: products });
      };
    
      const decreaseQuantity = (products) => {
        dispatch({ type: 'DECREASE_QUANTITY', payload: products });
      };
      const calculateTotal = () => {
        dispatch({ type: 'CALCULATE_TOTAL' });
    };
    useEffect(() => {
      calculateTotal(); 
    }, [state.card]);

    return (
        <CardContext.Provider value={{card:state.card, checkStock,  total: state.total, addToCard, calculateTotal, removeFromCard, increaseQuantity, decreaseQuantity, dispatch }}>
            {children}
        </CardContext.Provider>
    )
   
};

