import React from 'react';
import Header from '../components/Header.jsx';
import './Layout.css';
import Footer from '../components/Footer.js';
import { Container } from '@mui/material';

const Layout = ({ children }) => {
    return (
        
        <div>
            <Header /> 
            <div className="content"> 
                {children} 
            </div>
            
        </div>
       
    );
};

export default Layout;