import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams} from "react-router-dom";
import { Container } from '@mui/material';
import {StoreContext} from'../../context/StoreContext.js';
import "./StoreProduct.css";
import { ProductContext } from '../../context/ProductContext.js';
import { FaSearch } from 'react-icons/fa';
import { CategoryContext} from "../../context/CategoryContext.js";
import { FaBars, FaTimes, FaChevronDown, FaChevronUp  } from 'react-icons/fa';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import { FavoriteContext } from '../../context/FavoriteContext.js';


const StoreProduct=()=>{
  const {kullanici_id, kategori}=useParams();
  const {state, fetchStoreById, fetchStoreImage} =useContext(StoreContext);
  const {stores, loading, error, storee}=state;
  const {handleMaxPriceChange, handleMinPriceChange, filterByPrice, state:product } = useContext(ProductContext);
  const [hasMore, setHasMore] = useState(true);
  const{maxPrice, minPrice}=product;
  const{state:categoryState, selectedCategory}=useContext(CategoryContext);
  const{categories}=categoryState;
  const [openCategories, setOpenCategories] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const { favorites, addToFavorite, removeFavorite} = useContext(FavoriteContext);

  const handleProductClick = (sef) => {
    window.location.href = `/urun/${sef}`;
};
  const isFavorite = (product) => {
    return favorites.some(favProduct => favProduct.id === product.id);
};

const handleFavoriteToggle = (event, product) => {
    event.stopPropagation(); 
    if (isFavorite(product)) {
        removeFavorite(product);
    } else {
        addToFavorite(product);
    }
};
  useEffect(() => {
    if (kullanici_id) {
        fetchStoreById(kullanici_id, state.currentPage, kategori);  
       
    }
}, [kullanici_id,  fetchStoreById, kategori ]);

useEffect(() => {
  if (kullanici_id) {
      fetchStoreImage(kullanici_id);
  }
}, [ ]);
const handleScroll = useCallback(_.throttle(() => {
    const scrollThreshold = window.innerWidth < 768 ? 1400 : 700;
    if (loading || !hasMore) return;

    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold) {
      fetchStoreById(kullanici_id, state.currentPage); 
    }
  }, 300), [loading, hasMore, kullanici_id, fetchStoreById, state.currentPage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [handleScroll]);

const toggleCategory = (categoryId) => {
  if (openCategories.includes(categoryId)) {
    setOpenCategories(openCategories.filter((id) => id !== categoryId));
  } else {
    setOpenCategories([...openCategories, categoryId]);
  }
  selectedCategory(categoryId);
  
};


const ustKategoriler = categories.filter((kategori) => kategori.ust_kategori === 0 & kategori.ust_menu === 1);

const hasSubcategories = (categoryId) => {
  return categories.some((kategori) => kategori.ust_kategori === categoryId);
};


  if (loading) return <div>Yükleniyor...</div>;
  if (error) return <div>Hata: {error}</div>;
  if (!stores || stores.length === 0) return <div>Ürün Bulunamadı</div>;


      return( 
        
        <>
        <Container maxWidth="xl">
        <div className='magaza-photo'>
          <div className='magaza-photo-img'>

          {storee && storee.kapak_fotografi ? (
           <img src={`https://bikuyum.com/upload/${storee.kapak_fotografi}`} alt="Mağaza Kapak Fotoğrafı" />
            ) : (
           <p>Mağaza kapak fotoğrafı bulunamadı.</p>
           )}
           </div>
        </div>
        <div className="store-container">
        <div className='store-list'>
            <div className='sidebar-toggle' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
              {isSidebarOpen ? <FaTimes /> : <FaBars />} Kategoriler
            </div>
            <div className={`category-container ${isSidebarOpen ? 'open' : ''}`}>
              <div className='titlee'>
                <h3>Kategoriler</h3>
              </div>
              <div className='category-listt'>
                <ul>
                  {ustKategoriler.map((kategori) => (
                    <li key={kategori.id}>
                      <div className="categories-item">
                        <a
                          href={`/${kullanici_id}/${kategori.sef}`}
                         
                        >
                          {kategori.baslik}
                       
                          </a>
                        {hasSubcategories(kategori.id) && (
                          <span className="toggle-icon" onClick={() => toggleCategory(kategori.id)}>
                            {openCategories.includes(kategori.id) ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        )} 
                      </div>
                      
                             {openCategories.includes(kategori.id) && hasSubcategories(kategori.id) && (
                                 <ul>
                                     {categories
                                         .filter((altKategori) => altKategori.ust_kategori === kategori.id)
                                         .map((altKategori) => (
                                             <li key={altKategori.id}>
                                                 <a href={`${kullanici_id}/${altKategori.sef}`}>{altKategori.baslik}</a>
                                             </li>
                                         ))}
                                 </ul>
                             )}
                         </li>
                     ))}
                 </ul>
             </div>
            </div>
           
            <div className='categoriesfilter'>
            <label>Fiyat</label>
              <div className='price-inputs'>
          <input
            type="number"
            placeholder='En Az'
            value={minPrice}
            onChange={handleMinPriceChange}
          />
        
       
          <input
            type="number"
            placeholder='En Çok'
            value={maxPrice}
            onChange={handleMaxPriceChange}
          />
       <FaSearch className="search-icon" onClick={filterByPrice} />
        </div>
        
      </div>
          </div>
           
            <div className="store-product">
            
            {stores.map((prodct, indx) => (
              
                <div key={indx} className="store-product-card" 
                onClick={() => handleProductClick(prodct.sef)}
                style={{ cursor: 'pointer' }}>
                    <div className="store-img-container">
                    
                    <img src={prodct.image.i1} alt={prodct.baslik} />
                    <div 
                                        onClick={(e) => handleFavoriteToggle(e, prodct)} 
                                        className="favorite-iconn" 
                                    >
                                        <FontAwesomeIcon
                                icon={isFavorite(prodct) ? SolidHeart : RegularHeart}
                                style={{ color: isFavorite(prodct) ? 'red' : 'gray', fontSize: '24px' }}
                            />
                                    </div>
                    </div>

                    <div className="store-responsive-info">
            <h3 className="store-responsive-name">
                {prodct.baslik}
              </h3>
            <div className="ratingg">
              {'★'.repeat(5)}
            </div>
            <div className="pricee">
        {prodct.fiyat && prodct.eski_fiyat !== prodct.fiyat ? (
            <>
                <p className="originall-price" style={{ textDecoration: 'line-through' }}>
                    {prodct.eski_fiyat.toLocaleString('tr-TR')} TL
                </p>
                <p className="discountedd-price">
                    {prodct.fiyat.toLocaleString('tr-TR')} TL
                </p>
            </>
        ) : (
            <p className="discountedd-price">
                {prodct.fiyat.toLocaleString('tr-TR')} TL
            </p>
        )}
    </div>
             </div>
                </div>
            ))}
            </div>
            </div>
            </Container>
            </>
    );
};
export default StoreProduct;