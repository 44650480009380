import React, { useContext, useState, useEffect, useCallback } from 'react';
import './Payment.css'; 
import {PaymentContext} from "../../context/PaymentContext.js";
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Payment = () => {
  const {
    state: { cardNumber, totalAmount, cardHolderName, expiryDate, cvc, installments, commissionRate, selectedInstallment, loading, error, paymentSuccess },
    setCardNumber,
    setCardHolderName,
    setExpiryDate,
    setCvc,
    setTotalAmount,
    fetchCommissionRates,
    fetchInstallments,
    setSelectedInstallment,
    processPayment,  
  } = useContext(PaymentContext);
  const location = useLocation();
  const {total } = location.state || {}; 
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  useEffect(()=>{
    fetchCommissionRates();
  },[]);
  
  useEffect(() => {
    
    if (cardNumber.length >= 8) {
      fetchInstallments(cardNumber);
    }  else {
      
      setSelectedInstallment(null);
    }
  }, [cardNumber]);

  
  const handleCardNumberChange = (e) => {
    const newCardNumber = e.target.value; 
    setCardNumber(newCardNumber);
  };

  const handleInstallmentChange = (e) => {
    const selectedValue = Number(e.target.value);
    setSelectedInstallment(selectedValue);
  }; 
  const handleCardHolderNameChange = (e) => {
    setCardHolderName(e.target.value);
  };

 
  const handleExpiryDateChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    if (input.length >= 2) {
      input = input.slice(0, 2) + '/' + input.slice(2); 
    }
    setExpiryDate(input);
  };

  useEffect(() => {
    if (total) {
      setTotalAmount(total); 
    }
  }, [total]);

  const handleCvcChange = (e) => {
    setCvc(e.target.value);
  };
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (!cardNumber || !cardHolderName || !expiryDate || !cvc ) {
      alert('Lütfen tüm bilgileri eksiksiz doldurun!');
      return;
    }
    
    const selectedInstallmentData = installments.find(inst => inst.count === selectedInstallment);
    
    if (!selectedInstallmentData) {
      alert('Lütfen bir taksit seçin!');
      return;
    }

    const paymentData = {
      kart_numarasi:cardNumber,
      isim:cardHolderName,
      son_kullanma:expiryDate,
      ccv: cvc,
      taksit_orani:selectedInstallment,
      odenecek_tutar: selectedInstallmentData.totalAmount,
    };
    
    try {
      await processPayment(paymentData);
    } catch (err) {
      console.error('Ödeme sırasında hata oluştu1:', err);
    }
  };
  const defaultInstallments = [
    { count: 1, totalAmount:totalAmount},
    { count: 2, totalAmount: totalAmount  },
    { count: 3, totalAmount: totalAmount },
    
  ];
  {loading && <p>Yükleniyor...</p>}
      {error && <p>Hata: {error}</p>}
      {paymentSuccess && <p>Ödeme başarıyla gerçekleşti!</p>}
  return (
    <div className="payment-page">
      <h2>Ödeme Sayfası</h2>
      
      

      <div className="payment-container">
        <div className="payment-form">
          <form onSubmit={handlePaymentSubmit}>
            <div className="form-group">
              <label>Kart Numarası:</label>
              <input
                type="text"
                value={cardNumber}
                onChange={handleCardNumberChange}
                maxLength="16"
                placeholder="Kart numaranızı giriniz"
                required
              />
            </div>

            <div className="form-group">
              <label>Ad Soyad:</label>
              <input
                type="text"
                value={cardHolderName}
                onChange={handleCardHolderNameChange}
                placeholder="Adınızı Soyadınızı giriniz"
                required
              />
            </div>

            <div className="form-row">
            <div className="form-group small-group">
              <label>Son Kullanma Tarihi:</label>
              <input
                type="text"
                value={expiryDate}
                onChange={handleExpiryDateChange}
                placeholder="AA/YY"
                maxLength="5"
                required
              />
            </div>
            <div className="form-group small-group">
              <label>CVC:</label>
              <input
                type="text"
                value={cvc}
                onChange={handleCvcChange}
                placeholder="CVC"
                maxLength="3"
                required
              />
            </div>
            </div>
            <button type="submit" disabled={loading} className='payment-button'>
              {loading ? 'İşlem Yapılıyor...' : 'Ödeme Yap'}
            </button>
          </form>
          </div>
          <div className='payment-right'>
            <div className="installment-options">
      <h3>Taksit Seçenekleri</h3>
      <table className="installment-table">
        <thead>
          <tr>
            <th>Taksit Sayısı</th>
            <th>Aylık Ödeme</th>
            <th>Toplam Ödeme</th>
          </tr>
        </thead>
        <tbody>
        {(cardNumber.length < 8 ? defaultInstallments : installments).map((installment) => (
            <tr key={installment.count}>
              <td> <label>
                <input
                  type="radio"
                  name="installment"
                  value={installment.count}
                  checked={selectedInstallment === installment.count}
                  onChange={handleInstallmentChange}
                />
               {installment.count} Taksit</label>
              </td>
              <td>
                <span>{installment.totalAmount} TL</span>
              </td>
              <td>
                <span>{(installment.totalAmount)*(installment.count)} TL</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        <div className="commission-info">
          
          <button onClick={() => setIsOpen(true)}>Komisyon Oranları</button>
          {commissionRate.length > 0 ? (
            
            <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} contentLabel="Komisyon Oranları"  className="ReactModal__Content"
            overlayClassName="ReactModal__Overlay">
              <h2>Komisyon Oranları</h2>
              <button className="close-button" onClick={() => setIsOpen(false)}>
                        &times;</button>
              <table>
                <thead>
                  <tr>
                    <th>Banka</th>
                    <th>1 Taksit</th>
                    <th>2 Taksit</th>
                    <th>3 Taksit</th>
                  </tr>
                </thead>
                <tbody>
                  {commissionRate.map((bank) => {
                    const taksit1 = bank.taksit.find(t => t.count === 1)?.rate || 'N/A';
                    const taksit2 = bank.taksit.find(t => t.count === 2)?.rate || 'N/A';
                    const taksit3 = bank.taksit.find(t => t.count === 3)?.rate || 'N/A';
    
                    return (
                      <tr key={bank.name}>
                        <td>{bank.name}</td>
                        <td>{taksit1}</td>
                        <td>{taksit2}</td>
                        <td>{taksit3}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Modal>
       

          ) : (
            <p>Komisyon oranı mevcut değil.</p>
          )}
          </div> 
          </div> 
        
        </div>
      </div>
    
  );
};

export default Payment;