import React, { useContext, useEffect, useState, useRef } from 'react';
import { ProductContext } from '../../context/ProductContext.js';
import { Container } from '@mui/material';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import "./ProductList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart} from '@fortawesome/free-regular-svg-icons';
import {faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./CampaignProduct.css";

const CampaignProducts = () => {
    const { favorites, addToFavorite, removeFavorite} = useContext(FavoriteContext);
    const { state, fetchProducts, dispatch, fetchCampaignProduct  } = useContext(ProductContext);
    const { products, campaignProduct, loading, error } = state;
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const productContainerRef = useRef(null);


    useEffect(()=> {
        
            fetchCampaignProduct();
        
    },[]);
    const isFavorite = (product) => {
        return favorites.some(favProduct => favProduct.sef === product.sef);
    };

    const handleFavoriteToggle = (product) => {
        if (isFavorite(product)) {
            removeFavorite(product);
        } else {
            addToFavorite(product);
        }
    };

   
    useEffect(() => {
        const loadProducts = async () => {
            if (isFetching) return;  
            setIsFetching(true);  
            await fetchProducts(currentPage);
            setIsFetching(false); 
        };
        loadProducts();
    }, [currentPage ]);

    useEffect(() => {
        dispatch({ type: 'RESET_PRODUCTS' });
        setCurrentPage(1);
    }, []);

    
    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerWidth < 768 ? 1300 : 700;
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight  - scrollThreshold &&
                !isFetching &&
                !loading
            ) {
                setCurrentPage(prev => prev + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching, loading]);

    const scrollRight = () => {
        if (productContainerRef.current) {
            setScrollPosition(prev => {
                const newPosition = prev + 150;
                productContainerRef.current.scrollTo({ left: newPosition, behavior: 'smooth' });
                return newPosition;
            });
        }
    };

    const scrollLeft = () => {
        if (productContainerRef.current) {
            setScrollPosition(prev => {
                const newPosition = prev - 150;
                productContainerRef.current.scrollTo({ left: newPosition, behavior: 'smooth' });
                return newPosition;
            });
        }
    };

    if (loading) return <div>Yükleniyor...</div>;
    if (error) return <div>Hata: {error}</div>;

    
    return (
        <>
        <Container maxWidth="xl">
        <div className="campaign-container">
            <div className='campaign-title'>
            <h2>Kampanyalı Ürünler</h2>
            <h3><a href='/kampanyali-urunler/all'>Tüm Ürünler <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: '5px' }} /></a></h3>
            </div>
            <div className="campaign-products-wrapper">
                <button onClick={scrollLeft} className="scroll-button left">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <div className="campaign-products" ref={productContainerRef}>
                    {campaignProduct.map((product) => (
                        <div key={product.id} className="campaign-product-card">
                            <a href={`/urun/${product.sef}`}>
                                <img src={product.image.i1} alt={product.baslik} onError={(e) => { e.target.onerror = null; }} />
                            </a>
                            <FontAwesomeIcon
                                icon={isFavorite(product) ? SolidHeart : RegularHeart}
                                onClick={() => handleFavoriteToggle(product)}
                                className="favorite-icon"
                                style={{ color: isFavorite(product) ? 'red' : 'gray' }}
                            />
                            <div className="productt-card-responsive_info">
                            <h4 className="campaign-responsive-name"><a href={`/urun/${product.sef}`}>{product.baslik}</a></h4>
                            <div className="ratings">
                                 {'★'.repeat(5)}
                            </div>
                            </div>
                            <div className="price">
        {product.fiyat ? (
            <>
                <span className="original-price">{product.eski_fiyat.toLocaleString('tr-TR')} TL</span>
                <span className="discounted-price">{product.fiyat.toLocaleString('tr-TR')} TL</span>
            </>
        ) : (
            <span className="discounted-price">{product.fiyat.toLocaleString('tr-TR')} TL</span>
        )}
    </div>
                        </div>
                    ))}
                </div>
                <button onClick={scrollRight} className="scroll-button right">
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
           
        </div>
   

            </Container>
            </>
    );
};

export default CampaignProducts;