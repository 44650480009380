import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [formData, setFormData] = useState({
    ad: '',
    soyad: '',
    telefon: '',
    email: '',
    sifre: '',
    sifreOnay: '',
    sozlesmeOnay: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  
  const onSubmit = (e) => {
    e.preventDefault();

    
   const guest = {
    name: e.target.name.value,
    surname: e.target.surname.value,
    email: e.target.email.value,
    password: e.target.password.value,
    passwordConfirm: e.target.rePassword.value,
    consent:e.target.false,
    consent1: consent1,
    consent2: consent2,
   }
   
    axios
      .post( 'https://api.bikuyum.com/api/register', guest)
      .then((res) => {console.log(res.data);
       
          setTimeout(() => {
           alert("İşlem Başarılı");
              navigate("/login");
          }, 2000); 
        
      })
      .catch((error) => {
      
        console.error(error);
      });
      
      
  };
  
  return (
    <div className="main-wrapper">
      
        

          <div className="account-box">
            <div className="account-wrapper">
              <h3 className="account-title">Hesap Oluştur</h3>
      
              <form onSubmit={onSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="ad"
              placeholder="Ad"
              value={formData.ad}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="soyad"
              placeholder="Soyad"
              value={formData.soyad}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="tel"
              name="telefon"
              placeholder="Telefon Numarası"
              value={formData.telefon}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="email"
              name="email"
              placeholder="E-Posta Adresi"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="password"
              name="sifre"
              placeholder="Şifre"
              value={formData.sifre}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="password"
              name="sifreOnay"
              placeholder="Şifre Tekrarı"
              value={formData.sifreOnay}
              onChange={handleChange}
              required
            />
          </div>
                <div>
                <label className="consent-label">
          <input
            type="checkbox"
            onChange={() => setConsent1(!consent1)}
            required
          />
          <a href >Aydınlatma ve Rıza Metni </a> sözleşmesini okudum ve kabul ediyorum.
        </label><br></br>
        <label className="consent-label">
          <input
            type="checkbox"
            onChange={() => setConsent2(!consent2)}
            required
          />
         <a href > Üyelik </a> sözleşmesini okudum ve kabul ediyorum.
        </label></div>
                <div className="form-group text-center">
                  <button className="btn" type="submit">
                    Kayıt Ol
                  </button>
                </div>
                <div className="account-footer">
                  <p>
                    Zaten bir hesabınız var mı ?{" "}
                    <a href="/login">Giriş Yap</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
       
      
    </div>
  );
};

export default Register;
