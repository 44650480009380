import React, { useContext, useEffect } from 'react';
import { ProductContext } from '../context/ProductContext.js';
import { useLocation } from 'react-router-dom'; 
import "../assest/SearchResults.css";

const SearchResults = () => {
    const location = useLocation();
  const query = new URLSearchParams(location.search).get('query'); 
  const { state, searchProducts } = useContext(ProductContext);

  useEffect(() => {
    if (query) {
      searchProducts(query); 
    }
  }, [query]);

  return (
    <div className='search'>
      
      <h2>"{query}" için arama sonuçları</h2>
      <div className="search-result">
     
      {state.products.length === 0 ? (
          <p>Sonuç bulunamadı</p>
      ) : (
         <div className="search-result-product">
          
            <div className="search-result-grid">
            
            {state.products.map(product => (
                <div key={product.id} className="search-result-card">
                    <div className="search-result-img-container">
                    <a href={`/urun/${product.sef}`}>
                    <img
                src={Array.isArray(product.image) ? product.image[0] : product.image.i1}
                alt={product.name}
              /></a>
                       </div>
                    <div className="search-result-responsive-info">
            <h3 className="search-result-responsive-name">
                <a href={`/urun/${product.sef}`}>{product.baslik}</a>
              </h3>
            <div className="ratingg">
              {'★'.repeat(5)}
            </div>
            <p className="pricee">{product.fiyat.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</p>
            {!product.image.i1 && <p>Ürün fotoğrafı bulunamadı.</p>}
             </div>
                </div>
            ))}
            </div>
            </div>
         
      )}
    </div>
    </div>
    
  );
};

export default SearchResults;