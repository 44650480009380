import React, { createContext, useReducer, useEffect } from "react";

const initialState={
    institutionals:null,
    institutionalsCategori:[],
   
}
const institutionalReducer = (state, action)=>{
    switch(action.type){
        case 'SET_SELECTED_INSTITUTIONAL':
            return{...state, institutionals: action.payload};
        case 'FETCH_INSTITUTIONAL_CATEGORİ_SUCCESS':
            return{...state, institutionalsCategori: action.payload};

    default:
        return state;
    }
}

export const InstitutionalContext = createContext();

export const InstitutionalProvider=({children})=>{
    const [state, dispatch] = useReducer(institutionalReducer, initialState);

   
        const fetchInstitutional = async (sef) => {
          try {
            const response = await fetch(`https://api.bikuyum.com/api/page?page=${sef}`);
            const institutionals = await response.json();
            console.log(institutionals);
            dispatch({ type: 'SET_SELECTED_INSTITUTIONAL', payload: institutionals });
          } catch (error) {
            console.error('Error fetching contracts:', error);
          }
        };
       
      useEffect(() => {
        const fetchInstitutionalCategori = async () => {
          try {
            const response = await fetch('https://api.bikuyum.com/api/pages');
            const data = await response.json();
            dispatch({ type: 'FETCH_INSTITUTIONAL_CATEGORİ_SUCCESS', payload: data });
          } catch (error) {
            console.error('Error fetching contracts:', error);
          }
        };
        fetchInstitutionalCategori();
      }, []);
    

return (<InstitutionalContext.Provider value={{...state, fetchInstitutional}}>
        {children}
</InstitutionalContext.Provider>

);
};