import React, { useContext, useState, useEffect } from 'react';
import { CategoryContext } from '../../context/CategoryContext.js';
import { ProductContext } from '../../context/ProductContext.js';
import "./Categories.css";
import {  FaChevronDown, FaChevronUp  } from 'react-icons/fa';

const Categories =()=>{
    const { state: categoryState } = useContext(CategoryContext);

  const { categories, loading, error } = categoryState;
 
  const [menuOpen, setMenuOpen] = useState(false);
    const [openCategories, setOpenCategories] = useState([]);

    const toggleMenu = () => {
        setMenuOpen((prevState) => !prevState);
    };

    const toggleCategory = (categoryId) => {
      if (window.innerWidth <= 768) { 
        setOpenCategories((prev) =>
          prev.includes(categoryId)
            ? prev.filter((id) => id !== categoryId)
            : [...prev, categoryId]
        );
      }
    };
  
  const ustKategoriler = categories.filter((kategori) => kategori.ust_kategori === 0 && kategori.ust_menu === 1);

  const hasSubcategories = (categoryId) => {
    return categories.some((kategori) => kategori.ust_kategori === categoryId);
  };
  
  
if (loading) return <p>Kategoriler yükleniyor...</p>;
  if (error) return <p>Bir hata oluştu: {error}</p>;

    return (
      <>
       <div className="category-dropdown">
          <div className="hamburger-menu" onClick={toggleMenu} >☰ Menü</div>
          
           <ul className={`main-categories ${menuOpen ? "open" : ""}`}>
                {ustKategoriler.map((kategori) => (
                     <li
                     key={kategori.id}
                     className={`category-item ${openCategories.includes(kategori.id) ? "active" : ""}`}
                   >
                     <a
                       href={`/${kategori.sef}`}
                      
                     >
                       {kategori.baslik}</a>
                       {hasSubcategories(kategori.id) && (
                         <span className="toggle-icons" onClick={() => toggleCategory(kategori.id)}>
                           {openCategories.includes(kategori.id) ? <FaChevronUp /> : <FaChevronDown />}
                         </span>
                       )}
                     
                        <ul className={`sub-categories ${openCategories.includes(kategori.id) ? "show" : ""}`}>
              {categories
                .filter((altKategori) => altKategori.ust_kategori === kategori.id)
                .map((altKategori) => (
                  <li key={altKategori.id}>
                    <a href={`${altKategori.sef}`}>{altKategori.baslik}</a>
                  </li>
                ))}
            </ul>
                        
                    </li>
                ))}
            </ul>
        </div>
      
        
    </>
    );
};
export default Categories;