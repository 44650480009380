import React, {useState, useEffect} from "react";
import "../assest/Footer.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Container } from "@mui/material";
import payment from "../img/payment-method.png";


const Footer = () => {

    return (
     
      <footer className="footer">
     <Container maxWidth="xl">
      <div className="footer-container">

      <div className="footer-section">
        <h4>İletişim Bilgilerimiz</h4>
        <p><strong>Adres:</strong>  Hilton İş Merkezi ( Eski Hilton Otel ) İsmet Kaptan Mah Gazi Osmanpaşa Blv. Hilton İş Merkezi, Z14 Konak/İzmir</p>
        <p><strong>Telefon:</strong> <a href="tel:+905442323517">05442323517</a></p>
        <p><strong>E-mail:</strong> <a href="mailto:info@bikuyum.com">info@bikuyum.com</a></p>
        <div className="social-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.x.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </div>

      <div className="footer-section">
        
        <h4>Kategoriler</h4>
        <ul>
          <li><a href="/alyans">Alyans</a></li>
          <li><a href="/yuzuk">Yüzük</a></li>
          <li><a href="/kolye">Kolye</a></li>
          <li><a href="/bileklik">Bileklik</a></li>
          <li><a href="/kupe">Küpe</a></li>
          <li><a href="/kelepce">Kelepçe</a></li>
          <li><a href="/saat">Saat </a></li>
          <li><a href="/taki-setleri">Takı Setleri</a></li>
          <li><a href="/antika-urunlerimiz">Antika Ürünlerimiz</a></li>
          <li><a href="/ikici-el-altın">İkinci El Altın</a></li>
          <li><a href="/gümüs">Gümüş</a></li>
        </ul>
      </div>

      <div className="footer-section">
        <h4>Kurumsal</h4>
        <ul>
          <li><a href="institutional/dijital-magaza-kiralama">Dijital Mağaza Kiralama Sözleşmesi</a></li>
          <li><a href="institutional/aydinlatma-riza-metni">Aydınlatma ve Rıza Metni</a></li>
          <li><a href="institutional/kullanim-kosullari">Kullanım Koşulları</a></li>
          <li><a href="institutional/uyelik-sozlesmesi">Üyelik Sözleşmesi</a></li>
          <li><a href="institutional/mesafeli-satis-sozlesmesi">Mesafeli Satış Sözleşmesi</a></li>
          <li><a href="institutional/teslimat-iade">Telimat ve İade</a></li>
          <li><a href="institutional/gizlilik-politikasi">Gizlilik Politikası</a></li>
          <li><a href="institutional/hakkimizda">Hakkımızda</a></li>
        </ul>
      </div>

      <div className="footer-section">
        <h4>Site Haritası</h4>
        <ul>
          <li><a href="/openStore">Mağaza Aç</a></li>
          <li><a href="/login">Mağaza Girişi</a></li>
          <li><a href="/sepetim">Sepetim</a></li>
          <li><a href="/favorilerim">Favorilerim</a></li>
          <li><a href="/blog-yazilari">Blog Yazıları</a></li>
          <li><a href="/iletisim">İletişim</a></li>
        </ul>
      </div>

      <div className="footer-section">
        <h4>Uygulama ve Ödeme</h4>
        <p>BiKuyum.com'da SSL sertifikası ile Kredi Kartı Alışverişlerinde 128 Bit SSL Güvenlik Sertifikası Kullanılmaktadır.</p>
        <div className="payment-icons">
            <img src={payment} alt="" />
        </div>
      </div>

      
      </div>
      </Container>
      <div className="footer-bottom">
        <p>Copyright &copy; 2023 <a href="https://www.kuyumcuyazilimi.com">KuyumcuYazilimi.com</a> Tüm Hakları Saklıdır.</p>
      </div>
    </footer>
     
    );
  };
  
  export default Footer;